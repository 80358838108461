(function() {

    //create empty object in the global em var, dont forget to add the init call in the main.js!
    em.megaMenu = {};

    //call any functions to be trigger on dom ready
    em.megaMenu.init = function() {
        var el = document.querySelector('.js-mega-menu');
        if ( el !== null ) {
            em.megaMenu.setup( el );
        }
    };

    em.megaMenu.setup = function( menu ) {

        var globalHeaderHeight = Modernizr.mq('screen and (min-width: 992px)') ? $('.b-global-header').height() : 0;

        var headroom  = new Headroom(menu, {
            offset: globalHeaderHeight,
            tolerance : {
                up : 10,
                down : 5
            },
            classes: {
                // when element is initialised
                initial : "headroom",
                // when scrolling up
                pinned : "b-mega-menu--pinned",
                // when scrolling down
                unpinned : "b-mega-menu--unpinned",
                // when above offset
                top : "b-mega-menu--top",
                // when below offset
                notTop : "b-mega-menu--not-top",
                // when at bottom of scoll area
                bottom : "b-mega-menu--bottom",
                // when not at bottom of scroll area
                notBottom : "b-mega-menu--not-bottom"
            }
        });

        headroom.init();
    };

})();

(function() {

    if ($('.b-vue-contacts').length > 0) {

        var app = new Vue({
            el: ".b-vue-contacts",

            data: {
                posts: [],
                searchString: '',
                selectedCategories: [],
                paginationEnd: 12,
                perPage: 6,
                showLoadMore: false,
                businessFields: [],
                locations: []
            },

            // this hook is called after the instance is created
            created: function() {
                this.fetchPosts();

                var urlCategory = em.helper.getQueryVariable('fcat');

                if(urlCategory){

                    urlCategory = decodeURIComponent(urlCategory);

                    this.selectedCategories = urlCategory.split(','); 
                }

            },

            // define methods under the methods object
            methods: {

                fetchPosts: function() {

                    var self = this;

                    $.ajax({
                        url: '/wp-json/vapo/v1/contacts',
                        method: 'GET',
                        beforeSend: function(xhr) {},
                        data: {}
                    }).done(function(response) {
                        self.posts = response;

                        //lets populate the available categories
                        for (var i = 0; i < response.length; i++) {

                            //if we have biz fields
                            if (response[i].contact_business_field) {

                                //loop post categories
                                for (var j = 0; j < response[i].contact_business_field.length; j++) {

                                    //a duplication check
                                    if (self.businessFields.indexOf(response[i].contact_business_field[j]) > -1) continue;

                                    self.businessFields.push(response[i].contact_business_field[j]);
                                }
                            }

                            //if we have biz fields
                            if (response[i].contact_location) {

                                //loop post categories
                                for (var j = 0; j < response[i].contact_location.length; j++) {

                                    //a duplication check
                                    if (self.locations.indexOf(response[i].contact_location[j]) > -1) continue;

                                    self.locations.push(response[i].contact_location[j]);
                                }
                            }
                        }

                        //lets order the categories now
                        if (self.locations.length > 0) {
                            self.locations.sort(function(a, b) {
                                return a > b;
                            });
                        }

                        if (self.businessFields.length > 0) {
                            self.businessFields.sort(function(a, b) {
                                return a > b;
                            });
                        }

                    });

                },

                changeCategory: function(id) {

                    var index = this.selectedCategories.indexOf(id);

                    if (index > -1) {
                        this.selectedCategories.splice(index, 1);
                    }
                    else {
                        this.selectedCategories.push(id);
                    }

                    window.history.pushState(null, null, '?fcat=' + this.selectedCategories.join());
                },

                loadMore: function() {
                    this.paginationEnd = this.paginationEnd + this.perPage;
                }

            },

            //
            computed: {

                // A computed property that holds only those articles that match the searchString.
                filteredPosts: function() {

                    var postsArray = this.posts,
                        searchString = this.searchString,
                        selectedCategories = this.selectedCategories;

                    //if we have a search string lets filter the array
                    if (searchString) {
                        searchString = searchString.trim().toLowerCase();

                        postsArray = postsArray.filter(function(item) {
                            if (item.title.toLowerCase().indexOf(searchString) !== -1) {
                                return item;
                            }
                        });
                    }

                    //if we have a category select
                    if (this.selectedCategories.length > 0) {
                        postsArray = postsArray.filter(function(item) {

                            var check = false;

                            if(item.contact_business_field !== null){
                                for (var i = 0; i < item.contact_business_field.length; i++) {
                                   if (selectedCategories.indexOf(item.contact_business_field[i]) > -1) {
                                       check = true;
                                       break;
                                   }
                                }
                            }

                            if(item.contact_location !== null){
                                for (var i = 0; i < item.contact_location.length; i++) {
                                   if (selectedCategories.indexOf(item.contact_location[i]) > -1) {
                                       check = true;
                                       break;
                                   }
                                }
                            }

                            if(check){
                                return item;
                            }

                        });
                    }

                    //now lets paginate these posts
                    //paginatedPostsArray = postsArray.slice(0, this.paginationEnd);
                    paginatedPostsArray = postsArray;

                    this.showLoadMore = (postsArray.length > paginatedPostsArray.length) ? true : false;

                    // Return an array with the filtered data.
                    return paginatedPostsArray;
                }

            }
        });
    }

})();

(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.scrollFill = {};

	//call any functions to be trigger on dom ready
	em.scrollFill.init = function() {
		if( $('.js-text-image-reveal').length && $(window).width() > 1024 ){
			em.scrollFill.setup();
		}
	};

	em.scrollFill.setup = function() {

		var scrollHandling = {
			allow: true,
			reallow: function() {
				scrollHandling.allow = true;
			},
			delay: 10 // delay per scroll-check in ms
		};

		window.onscroll = function (event) {
			if(scrollHandling.allow) {
				// Get the distance between window top and the element top
				var scrollTop     = $(window).scrollTop(),
					elementOffset = $('.js-text-image-reveal').offset().top,
					elementHeight = $('.js-text-image-reveal').height(),
					distance      = elementOffset - scrollTop,
					elementStop   = elementOffset + elementHeight,
					progress      = ((scrollTop / elementOffset) * 100 / 1.5);

				// Start filling when we have reached the element and havent passed it
				if ( (scrollTop > distance) && (scrollTop < elementStop) ) {
					$('.js-text-image-reveal__reveal').css('height', progress+'%');
					// Manage scroll delay
					scrollHandling.allow = false;
					setTimeout(scrollHandling.reallow, scrollHandling.delay);
				} // end if in viewport
			} // end if scrollhandling.allow
		};

	};

})();

(function() {

	em.sticky = {};

	em.sticky.el = false;

	em.sticky.stickyNavTop = 0;

	em.sticky.init = function(){

			this.el = $('.is-sticky, .is-sticky--sidebar');

			if ( this.el.length ) {
				this.stickyNavTop = this.el.offset().top;

				var stickyNav = function(){
					var scrollTop = $(window).scrollTop();

					if (scrollTop > em.sticky.stickyNavTop) {
						$('.is-sticky, .is-sticky--sidebar').addClass('sticky');
					} else {
						$('.is-sticky, .is-sticky--sidebar').removeClass('sticky');
					}
				};

				stickyNav();

				$(window).scroll(function() {
					stickyNav();
				});

			}

  	};

})();

(function() {

  em.canvas = {};

  em.canvas.init = function() {
		em.canvas.setup();
	};

	em.canvas.setup = function() {

      /*var config = {
        type: 'line',
        data: {
          labels: ['2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
          datasets: [{
            label: 'Coal',
            fill: false,
            backgroundColor: 'rgba(80, 82, 91, 1)',
            borderColor: 'rgba(80, 82, 91, 1)',
            data: [
              45, 41, 33, 26, 18, 9, 0
            ],
          }, {
            label: 'Electricity',
            fill: false,
            backgroundColor: 'rgba(204, 0, 0, 1)',
            borderColor: 'rgba(204, 0, 0, 1)',
            data: [
              3, 5, 6, 7, 8, 9, 11, 15, 21, 25, 30, 36, 47
            ],
          }, {
            label: 'Peat',
            fill: true,
            zIndex: 0,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'rgba(234, 118, 16, 1)',
            data: [
              34, 34, 32, 30, 28, 24, 20, 16, 12, 8, 4, 0
            ],
          }, {
            label: 'Peat',
            fill: true,
            backgroundColor: 'rgba(234, 118, 16, 0.5)',
            borderColor: 'rgba(234, 118, 16, 1)',
            borderDash: [5, 5],
            data: [
              34, 34, 32, 30, 28, 24, 20, 16, 13, 10, 7, 4, 0
            ],
          }, {
            label: 'Biomass',
            fill: true,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'rgba(0, 148, 100, 1)',
            data: [
              56, 57, 58, 59.5, 62, 63, 64, 65, 66, 67, 67.5, 68, 69
            ],
          }, {
            label: 'Biomass',
            fill: true,
            backgroundColor: 'rgba(0, 148, 100, 0.5)',
            borderColor: 'rgba(0, 148, 100, 1)',
            borderDash: [5, 5],
            data: [
              56, 57, 58, 59.5, 62, 63, 64, 65, 66, 69, 71, 74, 78
            ],
          },
        ]},
        options: {
          responsive: true,
          title: {
            display: true,
            text: ''
          },
          hover: {
            mode: 'nearest',
            intersect: true,
            display: false
          },
          tooltips: {
            enabled: false
          },
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            yAxes: [
            {
              ticks: {
              //fontFamily: "Roboto Mono",
              fontColor: "#556F7B",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20
              },
              gridLines: {
              drawTicks: false,
              display: false,
              drawBorder: false
              }
            }
            ],
            xAxes: [
            {
              gridLines: {
              zeroLineColor: "transparent"
              },
              ticks: {
              padding: 20,
              fontColor: "#556F7B",
              fontStyle: "bold",
              //fontFamily: "Roboto Mono"
              },
              gridLines: {
              drawTicks: false,
              display: false,
              drawBorder: false
              }
            }
            ]
          }
        }
      };
  
      window.onload = function() {
        var ctx = document.getElementById('js-chart-canvas').getContext('2d');
        window.myLine = new Chart(ctx, config);
      };*/

  }; 

})();
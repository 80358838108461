(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.navigation = {};

	//call any functions to be trigger on dom ready
	em.navigation.init = function() {
		em.navigation.setup();
	};

	// TODO: Do these properly :) heres just a quick mockup to test styles
	em.navigation.setup = function() {
		// mobile toggle stuff
		$('.js-child-navigation-toggle').click(function(){
			if ($(window).width() < 992) {
				$(this).parent().toggleClass('is-active');
				$(this).next('.js-navigation-parent').toggleClass('is-open');
			}
		});

		var toggleText = $('.b-mega-menu__mobile-toggle-text');

		$('.js-mega-menu-toggle, .b-mega-menu__mobile-toggle-text').click(function(){
			$('body').toggleClass('has-open-menu');
			$('html').toggleClass('has-open-menu');

			//lets toggle the text
			toggleText.find('span').eq(0).toggleClass('hide');
			toggleText.find('span').eq(1).toggleClass('hide');
		});

		$(document)
			/** for MAIN MENU - The Spinoff from Mega Menu (created for Vapo Carbons) */
			.on('click', '.js-main-menu-toggle', function(){
				$('body,html').toggleClass('has-open-menu');
			})
			.on('click', '.js-main-menu a', function() {
				// close mobile overlay when clicking links because they are probably in-page links
				$('body,html').removeClass('has-open-menu');
			})
		;

		// search overlay stuff
		$('.js-mega-menu-search-toggle, .js-mega-menu-search-overlay').click(function(){
			$('.js-mega-menu-search-overlay').toggleClass('is-visible');
			setTimeout(function() {
				$(".js-desktop-searchfield").focus();
			}, 100);
		});
	};

})();

(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.parallaxScroll = {};

	//call any functions to be trigger on dom ready
	em.parallaxScroll.init = function() {
		if($(window).width() > 1024){
			em.parallaxScroll.setup();
		}
	};

	em.parallaxScroll.setup = function() {
		var s = skrollr.init({
			//'smoothScrolling': false,
			'forceHeight': false
		});

		$(window).on('resize', function () {
			if ($(window).width() <= 1024) {
				em.helper.debounce(skrollr.init().destroy(), 100, false);
			} else if ($(window).width() > 1024) {
				em.helper.debounce( skrollr.init({'forceHeight': false}), 100, false);
			}
		});
	};

})();

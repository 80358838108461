(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.example = {};

	//call any functions to be trigger on dom ready
	em.example.init = function() {
		em.example.setup();
	};

	em.example.setup = function() {
	
	};

})();

(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.steppirulla = {};

    em.steppirulla.breakpoint = 992;

    em.steppirulla.slickOptions = {
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 2,
        arrows: false,
        dots: false,
        infinite: false,
    };

	em.steppirulla.elements = [];

	//call any functions to be trigger on dom ready
	em.steppirulla.init = function() {

        this.elements = document.querySelectorAll('.js-steppirulla');

        if ( this.elements.length ) {
            for ( var i=0; i<this.elements.length; i++ ) {
                this.setup( this.elements[i] );
            }
        }

	};

	em.steppirulla.setup = function( el ) {

        $el = $(el);

        if ( window.innerWidth >= this.breakpoint ) {
            // $el.slick(em.steppirulla.slickOptions);
        }

        $el.on('click', '.b-steppirulla__item', function(event){
            var parent = $(event.target).hasClass('b-steppirulla__item') ? $(event.target) : $(event.target).parents('.b-steppirulla__item');
            var $img = parent.find('.b-steppirulla__pic img').clone();
            $el.parents('.b-steppirulla').find('.b-steppirulla__big-pic__frame').html($img);
        });

	};

})();

(function() {

    em.slider = {};

    em.slider.init = function(){

    $(".c-slider__container").slick({
        infinite: false,
        draggable: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              draggable: true,
            }
          }
        ]
    });


  };

})();

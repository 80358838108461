(function() {

    if ($('.b-vue-posts--cases').length > 0) {

        var app = new Vue({
            el: ".b-vue-posts--cases",

            data: {
                posts: [],
                searchString: '',
                selectedCategory: '',
                paginationEnd: 12,
                perPage: 6,
                showLoadMore: false,
                categories: [
                    { id: 0, name: 'All' }
                ]
            },

            // this hook is called after the instance is created
            created: function() {
                this.fetchPosts();

                var urlCategory = em.helper.getQueryVariable('fcat');

                if(urlCategory){
                    this.selectedCategory = parseInt(urlCategory);
                }
            },

            // define methods under the methods object
            methods: {

                fetchPosts: function() {

                    var self = this;

                    $.ajax({
                        url: '/wp-json/vapo/v1/cases',
                        method: 'GET',
                        beforeSend: function(xhr) {},
                        data: {}
                    }).done(function(response) {
                        self.posts = response;

                        var catsAdded = [];

                        //lets populate the available categories
                        for (var i = 0; i < response.length; i++) {

                            //if we have post categories
                            if (response[i].categories.length > 0) {

                                //loop post categories
                                for (var j = 0; j < response[i].categories.length; j++) {

                                    //a quick dirty duplication check
                                    if (catsAdded.indexOf(response[i].categories[j].term_id) > -1) continue;

                                    catsAdded.push(response[i].categories[j].term_id);

                                    self.categories.push({
                                        id: response[i].categories[j].term_id,
                                        name: response[i].categories[j].name
                                    });
                                }
                            }
                        }

                        //lets order the categories now
                        if (self.categories.length > 0) {
                            self.categories.sort(function(a, b) {
                                return a.name > b.name;
                            });
                        }

                    });

                },

                changeCategory: function(id) {
                    this.selectedCategory = id;
                    window.history.pushState(null, null, '?fcat=' + id);
                },

                loadMore: function() {
                    this.paginationEnd = this.paginationEnd + this.perPage;
                }

            },

            //
            computed: {

                // A computed property that holds only those articles that match the searchString.
                filteredPosts: function() {

                    var postsArray = this.posts,
                        searchString = this.searchString,
                        selectedCategory = this.selectedCategory;

                    //if we have a search string lets filter the array
                    if (searchString) {
                        searchString = searchString.trim().toLowerCase();

                        postsArray = postsArray.filter(function(item) {
                            if (item.title.toLowerCase().indexOf(searchString) !== -1) {
                                return item;
                            }
                        });
                    }

                    //if we have a category select
                    if (this.selectedCategory) {
                        postsArray = postsArray.filter(function(item) {

                            var check = false;

                            for (var i = 0; i < item.categories.length; i++) {
                               if (item.categories[i].term_id == selectedCategory) {
                                   check = true;
                                   break;
                               }
                            }

                            if(check){
                                return item;
                            }

                        });
                    }

                    //now lets paginate these posts
                    paginatedPostsArray = postsArray.slice(0, this.paginationEnd);

                    this.showLoadMore = (postsArray.length > paginatedPostsArray.length) ? true : false;

                    // Return an array with the filtered data.
                    return paginatedPostsArray;
                }

            }
        });
    }

})();

(function() {

    /**
     * Small popup glued to the bottom of the screen. Displayed only once!
     */

	em.sidePopup = {};

	em.sidePopup.init = function() {

        // Show the window only if it's not seen already.
        if (typeof(window.localStorage.sidePopupSeen) == 'undefined') {

        var popup = document.querySelector('.js-side-popup');

        if (popup) {

            // Make popup visible.
            popup.classList.remove('c-side-popup--hidden');

            // Add event listeners to buttons. Close modal and mark as seen.
            var els = document.querySelectorAll('.js-side-popup-close');
            for (var i = 0; i < els.length; i++) {
                els[i].addEventListener('click', function(e) {
                    // window.localStorage.sidePopupSeen = true;
                    popup.classList.add('c-side-popup--hidden');
                    e.preventDefault(); // don't want to jump to top of page
                });
            }

        }


        }

	};


})();

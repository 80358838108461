(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.splitCards = {
        padding: 10,
        canvasWidth: 80,
        lineColor: '#f8d5b7'
    };

	//call any functions to be trigger on dom ready
	em.splitCards.init = function() {
		em.splitCards.setup();
	};

	em.splitCards.setup = function() {
        var block = $('.js-split-cards');

        // Hide any existing canvases
        em.splitCards.hideCanvases(block);

        if (Modernizr.mq('only screen and (min-width: 1650px)')) {

            block.each(function() {
                em.splitCards.setupCanvas($(this));
            });
        }
	};

    em.splitCards.setupCanvas = function(block) {
        var container = block.find('.js-split-cards-container');
        var bubbles = block.find('.js-info-bubble');
        var bubbleSize = bubbles.width();
        var yOffset = block.offset().top;
        var xOffset = (block.width() - container.width()) / 2 - em.splitCards.padding;
        var currentOffset;
        var nextOffset;
        var canvas;

        for (var i = 0; i < bubbles.length - 1; i++) {
            currentOffset = bubbles.eq(i).offset().top - yOffset;
            nextOffset = bubbles.eq(i+1).offset().top - currentOffset - yOffset;
            canvas = em.splitCards.buildCanvas();

            canvas.css({
                'top': currentOffset + bubbleSize * 0.8,
                'left': xOffset - em.splitCards.padding - em.splitCards.canvasWidth
            });

            canvas.attr('width', em.splitCards.canvasWidth);
            canvas.attr('height', nextOffset - bubbleSize * 0.6);

            block.append(canvas);
            em.splitCards.drawLine(canvas);
        }
    };

    em.splitCards.drawLine = function(canvas) {
        var padding = 4;
        var minX = padding;
        var minY = padding;
        var maxX = canvas.width() - padding;
        var maxY = canvas.height() - padding;
        var ctx = canvas[0].getContext('2d');

        ctx.beginPath();
        ctx.moveTo(maxX, minY);
        ctx.bezierCurveTo(minX, minY + (maxY / 3), minX, maxY - (maxY / 3), maxX, maxY);

        ctx.lineCap = 'round';
        ctx.lineWidth = 4;
        ctx.strokeStyle = em.splitCards.lineColor;
        ctx.stroke();
    };

    em.splitCards.buildCanvas = function() {
        return $('<canvas class="c-info-bubble__canvas js-info-bubble-canvas"></canvas>');
    };

    em.splitCards.hideCanvases = function(block) {
        block.find('.js-info-bubble-canvas').remove();
    };

})();

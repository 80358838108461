(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.mainMenu = {};

	em.mainMenu.breakPoint = 992;

	//call any functions to be trigger on dom ready
	em.mainMenu.init = function() {
		var el = document.querySelector('.js-main-menu');
		if ( el !== null ) {
			em.mainMenu.setup( el );
		}
	};

	em.mainMenu.setup = function( menu ) {

		var globalHeaderHeight = Modernizr.mq('screen and (min-width: '+em.mainMenu.breakPoint+'px)') ? $('.b-global-header').height() : 0;

		var headroom  = new Headroom(menu, {
			offset: globalHeaderHeight,
			tolerance : {
				up : 10,
				down : 5
			},
			classes: {
				// when element is initialised
				initial : "headroom",
				// when scrolling up
				pinned : "b-main-menu--pinned",
				// when scrolling down
				unpinned : "b-main-menu--pinned", // make main menu always stay on screen
				// when above offset
				top : "b-main-menu--top",
				// when below offset
				notTop : "b-main-menu--not-top",
				// when at bottom of scoll area
				bottom : "b-main-menu--bottom",
				// when not at bottom of scroll area
				notBottom : "b-main-menu--not-bottom"
			}
		});

		headroom.init();
	};

})();

(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.energyStoryNavMobile = {};

	em.energyStoryNavMobile.el = null;
	em.energyStoryNavMobile.toggle = null;

	em.energyStoryNavMobile.props = {
        offset: 0
    };

	//call any functions to be trigger on dom ready
	em.energyStoryNavMobile.init = function() {

        this.el = document.querySelector('.js-energy-story-nav-mobile');
        this.toggle = document.querySelector('.js-energy-story-nav-mobile-toggle');

        if ( this.el && this.toggle ) {
            this.captureOffset( this.el );
            this.setup( this.el, this.toggle );
        }

	};

	em.energyStoryNavMobile.shouldWe = function() {
        if ( window.innerWidth < 992 ) {
            return true;
        }
        return false;
    };

    em.energyStoryNavMobile.show = function() {
        this.el.style.display = 'block';
    };

	em.energyStoryNavMobile.captureOffset = function( el ) {

        var bodyRect = document.body.getBoundingClientRect(),
            elRect = el.getBoundingClientRect();

        this.props.offset = elRect.top - bodyRect.top;

    };

	em.energyStoryNavMobile.setup = function( el, toggle ) {

        toggle.addEventListener('click', function(){
            if ( document.body.classList.contains('energy-story-mobile-nav-open') ) {
                document.body.classList.remove('energy-story-mobile-nav-open');
            } else {
                document.body.classList.add('energy-story-mobile-nav-open');
            }
        });

        $(el).on('click', 'a', function(event){
            document.body.classList.remove('energy-story-mobile-nav-open');
            el.querySelector('.c-energy-story-nav-mobile__current').innerHTML = event.target.innerHTML;
        });

        /*
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        $(window).on('scroll',function(){

            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > em.energyStoryNavMobile.props.offset) {
                el.classList.add('is-fixed');
            } else {
                el.classList.remove('is-fixed');
            }
        });
        */

	};

})();

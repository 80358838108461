(function() {

em.stickymenu = {};

  em.stickymenu.init = function(){

    var sections = $('section');
    var nav = $('.magellan-menu');
    var nav_height = nav.outerHeight();

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop();
      
      sections.each(function() {
        var top = $(this).offset().top - nav_height;
        var bottom = top + $(this).outerHeight();
        
        if (cur_pos >= top && cur_pos <= bottom) {
          nav.find('a').removeClass('active');
          sections.removeClass('active');
          
          $(this).addClass('active');
          nav.find('a[href="#'+$(this).attr('id')+'"]').addClass('active');
        }
      });
    });

    nav.find('a').on('click', function () {
      var $el = $(this),
      id = $el.attr('href');
      
      $('html, body').animate({
        scrollTop: $(id).offset().top - nav_height
      }, 500);
      
      return false;
    });

  };

})();
(function() {

    //create empty object in the global em var, dont forget to add the init call in the main.js!
    em.story = {
        mobileBreakpoint: 992,
        storyX: 20,
        cornerRadius: 50
    };

    //call any functions to be trigger on dom ready
    em.story.init = function() {

        var story = $('.b-story');

        if(story.length === 0){
            return false;
        }

        //lets loop the story sections and add data attr for easier selection
        story.find('.b-story__chapter section').each(function(i) {
            //as humans count from 1 and arrays start at 0, lets make this easier for them
            $(this).attr('data-story-block', (i + 1));
        });

        setTimeout(function() {
            em.story.setupDesktopLine();
        }, 500);

        em.story.setupMobileLine();

        //lets jump to a story section if in the url
        if(window.location.hash) {

            //abstract our story block id
            var id = window.location.hash.replace('#story-block-', '');

            //get our story block element
            var el = $('section[data-story-block="' + id + '"]');

            //if we have an element
            if (el.length === 1) {

                //grab its offset
                var target = el.offset();

                //finally scroll the body to it
                $('html,body').stop(true, true).animate({
                    scrollTop: target.top
                }, 1);
            }
        }
    };

    //this method is for our mobile story line only
    em.story.setupMobileLine = function() {

        var scrollOffset = 65;

        //lets get our chapter count
        var story = $('.b-story');

        //if no story is found or we are on a device not for this, christian bale out
        if (story.length === 0 || window.innerWidth >= em.story.mobileBreakpoint) {
            return false;
        }

        //lets grab copies of our dom elements
        var chapters = story.find('.b-story__chapter').eq(0).nextAll();
        var icons = story.find('.c-chapter-icon');
        var storyLine = story.find('.b-story__mobile-line');
        var line = story.find('.js-mobile-story-line');

        //set chapter data attr to we can select them easier
        if (storyLine.find('ul li').length === 0) {
            icons.each(function(index) {
                $(this).attr('data-chapter', index);
            });
        }

        //lets loop chapters and add a data attr for use later
        if (storyLine.find('ul li').length === 0) {
	        chapters.each(function(index) {
                //grab chapter icon component
                var icon = $('.c-chapter-icon[data-chapter="' + index + '"]');

                //and append into the list item
                var isActive = index === 0 ? ' class="active"' : '';
	            var item = storyLine.find('ul').append('<li data-chapter="' + index + '"' + isActive + '>'+icon.html()+'</li>');
	            $(this).attr('data-chapter', index);
	        });
	    }

        //create a click handler for scroll jump
        $('li[data-chapter]').on('click', function() {
            var id = $(this).attr('data-chapter');
            var el = $('div[data-chapter="' + id + '"]');
            if (el.length === 0) return false;
            var target = el.offset();
            $('body').stop().animate({
                scrollTop: target.top
            }, 400);
        });

        // set storyLine size

        //grab our storyline offset
        //var elementPosition = storyLine.offset();
        var elementPosition = story.offset();

        //grab our current scroll position
        var ourScrollPos = 0;

        //put this global?
        $(window).scroll(function() {

            //grab the current vertical position of the scroll bar
            var ourCurScrollPos = $(this).scrollTop();

            //grab our scroll direction so we can react differently to the story line
            var scrollDirection = (ourCurScrollPos > ourScrollPos) ? 'down' : 'up';

            //lets check if our storyline is in view and change fixed classes
            var isScrolledPast = $(window).scrollTop() > (elementPosition.top + 45);
            var isFixed = isScrolledPast && $(window).scrollTop() < (elementPosition.top + story.height() - $(window).height());

            if (isScrolledPast) {
                storyLine.addClass('is-scrolled');
            } else {
                storyLine.removeClass('is-scrolled');
            }

            if (isFixed) {
                storyLine.addClass('is-fixed');
            } else {
                storyLine.removeClass('is-fixed');
            }

            //or if the bottom of the fixed bar is at the bottom of the story block

            //as we are scrolling which chapter is in view?
            var activeChapter;

            chapters.each(function(index) {

                //retrieve the current position of an element relative to the document
                var chapterPosition = $(this).offset();
                //var viewPortOffset = scrollOffset + 1;
                var viewPortOffset = (storyLine.height() / chapters.length * index) + scrollOffset + (16.5 * (index - 1)); // magic number is magic
                //var viewPortOffset = $(window).height() / 2;

                //if element is at the top, need to change to middle or bottom
                if ($(window).scrollTop() > chapterPosition.top - viewPortOffset) {

                    //add active class
                    storyLine.find('li[data-chapter="' + index + '"]').addClass('active');
                    activeChapter = index;

                } else {

                    //if we are scrolling up lets deactivate the chapters after this one
                    if (scrollDirection == 'up') {
                        if (index > 0) {
                            storyLine.find('li[data-chapter="' + (index - 1) + '"]').nextAll().removeClass('active');
                        }
                    }
                }
            });

            // Display the active line on the correct position
            var scrollToPercentage = 0;

            if (typeof activeChapter !== 'undefined') {

                // Calculate the base distance based on amount of chapters
                //var viewPortOffset = $(window).height() / 2;
                //var viewPortOffset = scrollOffset + 1;
                var viewPortOffset = (storyLine.height() / chapters.length * activeChapter) + scrollOffset + (16.5 * (activeChapter - 1)); // magic number is magic
                var ticks = chapters.length - 1;
                var tickHeight = 100 / ticks;
                var baseDistance = activeChapter * tickHeight;
                var isNextChapter = activeChapter < chapters.length - 1;
                var currentOffset = $(chapters[activeChapter]).offset().top;
                var nextOffset;
                var offsetDistance;

                // Get either the next chapter's offset or the end of the story
                if (isNextChapter) {
                    nextOffset = $(chapters[activeChapter + 1]).offset().top;
                    offsetDistance = tickHeight * ((ourCurScrollPos + viewPortOffset) - currentOffset) / (nextOffset - currentOffset);
                    scrollToPercentage = baseDistance + offsetDistance;
                } else {
                    scrollToPercentage = baseDistance;
                }
            }

            line.css({
                'height': scrollToPercentage + '%'
            });

            //store our current scroll for next scroll comparison
            ourScrollPos = ourCurScrollPos;

        });

    };

    em.story.setupDesktopLine = function() {

        //grab ourselves some local copies of our dom elements
        var story = $('.b-story');
        var canvas = {
            inactive: story.find('.js-story-canvas-inactive'),
            active: story.find('.js-story-canvas-active')
        };

        //if mobile lets usain bolt out of here
        if (window.innerWidth < em.story.mobileBreakpoint || story.length === 0) {
            canvas.inactive.hide();
            canvas.active.hide();
            return false;
        }

        em.story.drawLine(story, canvas.inactive);
        em.story.drawLine(story, canvas.active);

        // Set the active line's wrappers size
        em.story.setActiveViewportSize(story);

        // Let's setup the chapter icons
        em.story.setupChapterIcons(story);
        em.story.setActiveChapterIcons(story);

        // If you enable a transition on the wrapper, you need to delay setting the active chapter icons
        // setTimeout(function() {
        //     em.story.setActiveChapterIcons(story);
        // }, 366);

        // Let's do some cool stuff on scroll too
        $(window).scroll(function() {
            em.story.setActiveViewportSize(story);
            em.story.setActiveChapterIcons(story);
        });
    };

    em.story.setActiveViewportSize = function(story) {
        var lineWrapper = story.find('.js-story-line-wrapper');
        var maxHeight = story.height();
        var lineHeight = Math.ceil($(window).scrollTop() + $(window).height() / 2 - lineWrapper.offset().top);

        // Set the limits
        if (lineHeight > maxHeight) {
            lineHeight = maxHeight;
        } else if (lineHeight < 0) {
            lineHeight = 0;
        }

        lineWrapper.css({
            'height': lineHeight
        });
    };

    em.story.getChapterBlockInfo = function(story) {
        var chapterBlockInfo = [];

        story.find('.b-story__chapter section').each(function(i) {
            chapterBlockInfo[i] = {};
            chapterBlockInfo[i].el = $(this);
            chapterBlockInfo[i].top = chapterBlockInfo[i].el.position().top;
            chapterBlockInfo[i].end = chapterBlockInfo[i].el.position().top + chapterBlockInfo[i].el.outerHeight(true);
        });

        return chapterBlockInfo;
    };

    em.story.drawLine = function(story, canvas) {
        var blockContainer = story.find('.b-story__block__container').eq(0);
        var storyWidth = blockContainer.width();
        var chapterBlockInfo = em.story.getChapterBlockInfo(story);
        var canvasWidth = storyWidth + (storyWidth * 0.09);
        var canvasInitialOffset = story.find('.b-story__block__container:eq(0)').position().top;
        var magicY = chapterBlockInfo[5].el.find('.l-story__column:last-child').outerWidth(true);
        var magicYPadding = chapterBlockInfo[5].el.find('.l-story__column:last-child').css('padding-left');
        var magicX = magicY - (parseInt(magicYPadding) / 2) - 10;

        // show our canvas
        canvas.show();

        //set new dimensions of our canvas to fit the background of our story
        canvas.attr('width', canvasWidth); //add 15% for padding
        canvas.attr('height', story.outerHeight(true));

        //this returns an object that provides methods and properties for drawing on the canvas
        var ctx = canvas[0].getContext('2d');
        ctx.translate(0.5, 0.5); //fix some blurrrrr
        ctx.beginPath(); //begins a path

        function midWayPointCurve() {
            return (storyWidth / 2) - em.story.cornerRadius;
        }

        //move to our starting place
        ctx.moveTo(em.story.storyX, chapterBlockInfo[0].top + canvasInitialOffset);

        ctx.globalCompositeOperation = 'destination-over';

        //chapter 1

        //in groups of right angles so make it easier to work with the arcs
        ctx.lineTo(em.story.storyX, chapterBlockInfo[0].end - (em.story.cornerRadius * 2)); //draw line down

        ctx.arcTo(em.story.storyX, chapterBlockInfo[0].end, em.story.storyX + em.story.cornerRadius, chapterBlockInfo[0].end, em.story.cornerRadius); //ctx.arcTo(x1,y1,x2,y2,r);

        ctx.lineTo((storyWidth / 2) - em.story.cornerRadius, chapterBlockInfo[0].end); //draw line across

        ctx.arcTo(midWayPointCurve() + em.story.cornerRadius, chapterBlockInfo[0].end, midWayPointCurve() + em.story.cornerRadius, chapterBlockInfo[0].end + (em.story.cornerRadius * 2), em.story.cornerRadius);

        ctx.lineTo(midWayPointCurve() + em.story.cornerRadius, chapterBlockInfo[1].end - em.story.cornerRadius); //draw line down

        ctx.arcTo(midWayPointCurve() + em.story.cornerRadius, chapterBlockInfo[1].end, midWayPointCurve() - em.story.cornerRadius, chapterBlockInfo[1].end, em.story.cornerRadius);

        ctx.lineTo(em.story.storyX + em.story.cornerRadius, chapterBlockInfo[1].end); //draw line across to left

        ctx.arcTo(em.story.storyX, chapterBlockInfo[1].end, em.story.storyX, chapterBlockInfo[1].end + em.story.cornerRadius, em.story.cornerRadius); //ctx.arcTo(x1,y1,x2,y2,r);

        //chapter 2

        ctx.lineTo(em.story.storyX, chapterBlockInfo[2].end - em.story.cornerRadius); //draw line down

        ctx.arcTo(em.story.storyX, chapterBlockInfo[2].end, em.story.storyX + em.story.cornerRadius, chapterBlockInfo[2].end, em.story.cornerRadius); //ctx.arcTo(x1,y1,x2,y2,r);

        ctx.lineTo(storyWidth - em.story.cornerRadius, chapterBlockInfo[2].end); //draw line across

        ctx.arcTo(storyWidth, chapterBlockInfo[2].end, storyWidth, chapterBlockInfo[2].end + em.story.cornerRadius, em.story.cornerRadius); //ctx.arcTo(x1,y1,x2,y2,r);

        //a little hide behind an image
        ctx.lineTo(storyWidth, chapterBlockInfo[4].end - 300); //draw line down

        ctx.lineTo(magicX, chapterBlockInfo[4].end - 300); //draw line across

        //chapter 3

        ctx.lineTo(magicX, chapterBlockInfo[5].end - em.story.cornerRadius); //draw line down

        ctx.arcTo(magicX, chapterBlockInfo[5].end, magicX - em.story.cornerRadius, chapterBlockInfo[5].end, em.story.cornerRadius); //ctx.arcTo(x1,y1,x2,y2,r);

        ctx.lineTo(em.story.storyX + em.story.cornerRadius, chapterBlockInfo[5].end); //draw line across

        //chapter 4

        ctx.arcTo(em.story.storyX, chapterBlockInfo[5].end, em.story.storyX, chapterBlockInfo[5].end + em.story.cornerRadius, em.story.cornerRadius); //ctx.arcTo(x1,y1,x2,y2,r);

        ctx.lineTo(em.story.storyX, chapterBlockInfo[6].end - em.story.cornerRadius); //draw line down

        //chapter 5

        ctx.arcTo(em.story.storyX, chapterBlockInfo[6].end, em.story.storyX + em.story.cornerRadius, chapterBlockInfo[6].end, em.story.cornerRadius); //ctx.arcTo(x1,y1,x2,y2,r);

        ctx.lineTo((storyWidth / 2) + em.story.cornerRadius, chapterBlockInfo[6].end); //draw line across

        //set some properties
        ctx.lineWidth = 5;
        ctx.strokeStyle = canvas.data('color');
        ctx.lineCap = 'round';
        ctx.stroke();

        ctx.globalCompositeOperation = 'source-over';

        //hide so we can fade in
        canvas.addClass('js-show');
    };

    em.story.setupChapterIcons = function(story) {
        var canvas = story.find('.js-story-canvas-inactive');
        var canvasPosition = canvas.offset();
        var canvasWidth = canvas.width();
        var canvasXOffset = ($(window).width() - canvasWidth) / 2;
        var chapterIcons = story.find('.c-chapter-icon');
        var chapterBlockInfo = em.story.getChapterBlockInfo(story);
        var iconHeight = chapterIcons.eq(1).outerHeight(true);
        var iconWidth = chapterIcons.eq(1).outerWidth(true);
        var iconHalfHeight = iconHeight / 2;
        var iconHalfWidth = iconWidth / 2;
        var canvasIconCenter = (canvasPosition.left + canvas.width()/2) - iconHalfHeight;
        var canvasInitialOffset = story.find('.b-story__block__container:eq(0)').position().top;
        var block = story.find('.b-story__block').eq(0);
        var sectionPadding = parseInt(block.css('paddingTop'));
        var magicY = chapterBlockInfo[5].el.find('.l-story__column:last-child').outerWidth(true);
        var magicYPadding = chapterBlockInfo[5].el.find('.l-story__column:last-child').css('padding-left');
        var magicX = magicY - (parseInt(magicYPadding) / 2) - 10;

        chapterIcons.each(function( index ) {
            var el = $(this);

            el.hide();
            el.css('left', canvasXOffset + em.story.storyX - iconHalfWidth);
            el.fadeIn();

            if (index === 0) {
                el.addClass('active');
            }
        });

        //chapter 1
        chapterIcons.eq(0).css({'top': chapterBlockInfo[0].top + canvasInitialOffset - iconHalfHeight * 0.5});

        //chapter 2
        chapterIcons.eq(1).css({'top': chapterBlockInfo[2].top + sectionPadding - iconHalfHeight * 0.5});

        //chapter 3
        chapterIcons.eq(2).css({'top': (chapterBlockInfo[2].end - iconHalfHeight), 'left': canvasIconCenter});

        //chapter 4
        //x is relative to canvas, so we need canvas left plus + 2/3
        chapterIcons.eq(3).css({'left': magicX + canvasXOffset - iconHalfWidth});

        //chapter 5
        chapterIcons.eq(4).css({'top': chapterBlockInfo[6].top + sectionPadding - iconHalfHeight * 0.5});

        //chapter 6
        chapterIcons.eq(5).css({'top': (chapterBlockInfo[6].end - iconHalfHeight), 'left': canvasIconCenter});
    };

    em.story.setActiveChapterIcons = function(story) {
        var lineWrapper = story.find('.js-story-line-wrapper');
        var linePosition = lineWrapper.offset().top;
        var lineHeight = lineWrapper.height();
        var chapterIcons = story.find('.c-chapter-icon');

        chapterIcons.each(function( index) {
            var el = $(this);
            var iconPosition = el.offset().top;

            // Ignore the first icon
            if (index !== 0) {
                if (lineHeight >= iconPosition - linePosition) {
                    el.addClass('active');
                } else {
                    el.removeClass('active');
                }
            }
        });
    };

})();

(function() {

    em.slideList = {};

    em.slideList.elements = false;

    em.slideList.init = function(){

        this.elements = $('.js-card-slideshow');

        if ( ! this.elements.length ) {
            return false;
        }

        if ( window.innerWidth <= 640 ) {
            $('.js-card-slideshow-spacer').remove();
        }

        this.elements.on('init', function(event, slick){
            $(event.target).parents('.is-about-to-get-slick').removeClass('is-about-to-get-slick');
        });

        this.elements.not('.slick-initialized')

            .on('mousedown touchstart', function(event) {
                $(event.target).parents('.b-slide-list__slides').addClass('is-sliding');
            })

            .on('mouseup touchend', function(event) {
                $(event.target).parents('.b-slide-list__slides').removeClass('is-sliding');
            })

            .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                slick.$slider.parent().addClass('is-sliding');
                if ( nextSlide >= 1 ) {
                    slick.$slider.parent().addClass('hide-intro');
                } else {
                    slick.$slider.parent().removeClass('hide-intro');
                }
            })

            .on('afterChange', function(event, slick, currentSlide, nextSlide){
                slick.$slider.parent().removeClass('is-sliding');
            })

            .slick({
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                rows: 1,
                dots: true,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ]
            })
        ;

    };

})();

(function() {

  em.energySlider = {
    sliderOptions : {
      wrapAround: false,
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
    },
    navOptions : {
      wrapAround: false,
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
    }
  };

  em.energySlider.init = function(){

    var sliders = $(".js-energy-slider");

    if ( sliders.length > 0 ) {
      for( var i = 0; i < sliders.length; i++ ) {
        this.setup( sliders[i] );
      }
    }

  };

  em.energySlider.setup = function( el ){

    $(el).flickity( this.sliderOptions );

    if ( el.getAttribute('data-nav') ) {
      var $nav = $(el.getAttribute('data-nav'));
      if ( $nav.length ) {
        var options = this.navOptions;
        options.asNavFor = el;
        $nav.flickity( options );
      }
    }

  };

})();

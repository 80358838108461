(function() {

    //create empty object in the global em var, dont forget to add the init call in the main.js!
    em.chart = {};

    //call any functions to be trigger on dom ready
    em.chart.init = function(){
        em.chart.options();
        em.chart.capture();
        em.chart.checkRequiredWidth();
        em.chart.setup();

        // $(window).resizeend(function() {
        // });
        // moved to -> resize.js
        // Get the context of the canvas element we want to select


        /**
         * Trigger a manual window resize event after everything has been loaded
         * to make the charts take correct size on load. This has to be done
         * because Slick + ChartJS responsiveness are not an ideal combination.
         *
         * Therefore, a more clean approach would be to replace the Slick slider
         * with something else, to drop the responsive option from Chartjs, or
         * something else altogether.
         *
         * For now, this solution works bar resizing from one device size to other.
         *
         * NOTE: this breaks javascript on IE so added checkup for it
         * @link  http://stackoverflow.com/a/19999868/2261856
         */
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer do nutin'
        } else {
            window.dispatchEvent(new Event('resize'));
        }
    };

    em.chart.options = function() {

        // Global settings
        // @link http://www.chartjs.org/docs/#chart-configuration-global-configuration
        // Chart.defaults.global.defaultColor = [
        //     '#f68e1f',
        //     '#ffba00',
        //     '#62cf21',
        //     '#5d479d',
        //     '#009fe3',
        //     '#ed008c',
        //     '#ffe400',
        //     '#ee2e22',
        //     '#6e5c4d',
        //     '#949499',
        //     '#e6e6e6'
        // ];
        Chart.defaults.global.maintainAspectRatio = false;
        Chart.defaults.global.defaultColor = '#009464';

        // Legend settings
        // @link http://www.chartjs.org/docs/#chart-configuration-legend-configuration
        Chart.defaults.global.legend.display = false;
        Chart.defaults.global.legend.position = 'bottom';
        Chart.defaults.global.legend.labels.boxWidth = 12;
        Chart.defaults.global.legend.labels.fontFamily ="'Roboto', 'Helvetica', sans-serif";
        Chart.defaults.global.legend.labels.padding = 16;

        // Tooltip settings
        // @link http://www.chartjs.org/docs/#chart-configuration-tooltip-configuration
        Chart.defaults.global.tooltips.enabled = true;
        Chart.defaults.global.tooltips.titleFontFamily ="'Roboto', 'Helvetica', sans-serif";
        Chart.defaults.global.tooltips.titleFontColor = '#000';
        Chart.defaults.global.tooltips.bodyFontFamily = "'Roboto', 'Helvetica', sans-serif";
        Chart.defaults.global.tooltips.bodyFontColor = '#000';
        Chart.defaults.global.tooltips.backgroundColor = '#fff';
        Chart.defaults.global.tooltips.caretSize = 3;
        Chart.defaults.global.tooltips.cornerRadius = 3;
        Chart.defaults.global.tooltips.xPadding = 15;
        Chart.defaults.global.tooltips.yPadding = 12;
        Chart.defaults.global.tooltips.callbacks.label = function(tooltipItem, data) { return tooltipItem.yLabel; };

        // GridLine settings
        // @link http://www.chartjs.org/docs/#grid-line-configuration
        // Chart.defaults.scale.gridLines.display = true;
        // Chart.defaults.scale.gridLines.color = '#62cf21';
        // Chart.defaults.scale.gridLines.borderDash = [4, 2];
        // Chart.defaults.scale.gridLines.borderDashOffset = 0;
        // Chart.defaults.scale.gridLines.lineWidth = 1;
        // Chart.defaults.scale.gridLines.drawBorder = true;
        // Chart.defaults.scale.gridLines.drawOnChartArea = true;
        // Chart.defaults.scale.gridLines.drawTicks = false;
        // Chart.defaults.scale.gridLines.tickMarkLength = 5;
        // Chart.defaults.scale.gridLines.zeroLineWidth = 0;
        // Chart.defaults.scale.gridLines.zeroLineColor = 'transparent';
        // Chart.defaults.scale.gridLines.offsetGridLines = false;

        // ScaleLabel settings
        // @link http://www.chartjs.org/docs/#scale-title-configuration
        Chart.defaults.scale.scaleLabel.display = false;
        Chart.defaults.scale.scaleLabel.labelString = '';
        Chart.defaults.scale.scaleLabel.fontColor = '#50525b';
        Chart.defaults.scale.scaleLabel.fontFamily = "'Roboto', 'Helvetica', sans-serif";
        Chart.defaults.scale.scaleLabel.fontSize = 12;
        Chart.defaults.scale.scaleLabel.fontStyle = 'normal';

        // Ticks settings
        // @link http://www.chartjs.org/docs/#ticks-configuration
        Chart.defaults.scale.ticks.fontColor = '#858585';
        Chart.defaults.scale.ticks.fontFamily = "'Roboto', 'Helvetica', sans-serif";
        Chart.defaults.scale.gridLines.color = '#c3c3c3';

        // Line Chart Options
        // @link http://www.chartjs.org/docs/#line-chart-chart-options (ChartJS 2.4.0)
        em.chart.options.line = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        };

        // Bar Chart Options
        // @link http://www.chartjs.org/docs/#bar-chart-chart-options (ChartJS 2.4.0)
        em.chart.options.bar = {
            scales: {
                xAxes: [{
                    categoryPercentage: 0.8,
                    barPercentage: 0.9
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5
                    }
                }]
            },
            tooltips: {
                mode: 'index',
                intersect: false
            }
        };

        // Bar Chart Options
        // @link http://www.chartjs.org/docs/#bar-chart-chart-options (ChartJS 2.4.0)
        // @note this used to be created with an add-on rather than directly with ChartJS (it wasn't supported at the time)
        em.chart.options.barstacked = {
            scales: {
                xAxes: [{
                    stacked: true,
                    barThickness: 25,
                    categoryPercentage: 0.5,
                    barPercentage: 1
                }],
                yAxes: [{
                    stacked: true,
                    barThickness: 25
                }]
            },
            tooltips: {
                mode: 'index',
                intersect: false
            }
        };

        // Pie charts were replaced with doughnut charts
        em.chart.options.pie = {};

        // Doughnut Chart Options
        // @link http://www.chartjs.org/docs/#doughnut-pie-chart-chart-options (ChartJS 2.4.0)
        em.chart.options.doughnut = {
            legend: {
                display: true
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {

                        // get the label
                        var label = data.labels[tooltipItem.index] || "";

                        return label;
                    }
                }
            }
        };

        // Radar Charts are not being used
        // em.chart.options.radar = {};

        // Polar Area Charts are not being used
        // em.chart.options.polar = {};

        // list of options at https://inorganik.github.io/countUp.js/
        em.chart.options.counter = {
            useEasing : true,
            useGrouping : true,
            separator : ' ',
            decimal : ' ',
            prefix : '',
            suffix : ''
        };
    };

    em.chart.build = function(el, type, options) {
        type = typeof type !== 'undefined' ? type : 'line';
        options = typeof options !== 'undefined' ? options : {};

        var data = el.data('chart');
        var canvas = el.find('.c-chart__item__canvas');
        var ctx;

        if (canvas.length > 0) {
            ctx = canvas[0].getContext("2d");
        }

        switch (type) {
            case 'line':
                return new Chart(ctx, {type: 'line', data: data, options: options});
            case 'bar':
            case 'barstacked':

                var barGraph = new Chart(ctx, {type: 'bar', data: data, options: options});

                return barGraph;
            case 'pie':
                return new Chart(ctx, {type: 'pie', data: data, options: options});
            case 'doughnut':
                return new Chart(ctx, {type: 'doughnut', data: data, options: options});
            case 'radar':
                return new Chart(ctx, {type: 'radar', data: data, options: options});
            case 'polar':
                return new Chart(ctx, {type: 'polarArea', data: data, options: options});
            case 'counter':
                var counter = new CountUp(el.find('.c-chart__item__content')[0], data.startValue, data.endValue, 0, data.speed, options);
                counter.start();
                return counter;
            default:
                return false;
        }

    };

    // em.chart.setBorderWidth = function(el) {
    //     var canvas = el.find('.c-chart__item__canvas');
    //     var border = el.find('.c-chart__item__border');

    //     border.css({
    //         'width': canvas.width()
    //     });
    // };

    em.chart.capture = function(){
        em.chart.elements = $('.c-chart__item');
    };

    em.chart.checkRequiredWidth = function(){
        if(window.innerWidth>1024){
            em.chart.winWidthOk = true;
            em.chart.animate();
        } else if(navigator.userAgent === 'muumi') {
            em.chart.winWidthOk = false;
        }
        else {
            em.chart.winWidthOk = false;
        }
    };

    em.chart.canWe = function(){
        if(em.chart.elements.length && em.chart.winWidthOk === true) {
            return true;
        }

        return false;
    };

    em.chart.setup  = function(){

        var canWe = em.chart.canWe();

        // if on mobile, build charts immediately
        if(!canWe) {
            em.chart.elements.each(function(){
                var el = $(this),
                    type = el.data('type');

                el.chart = em.chart.build(el, type, em.chart.options[type]);
                el.addClass('initialized');
            });
        }

        // otherwise let's wait for them to scroll into view
        $(window).on("scroll", function() {

            if(!canWe){
                return false;
            }

            em.chart.animate();

        }).scroll();

        // just to be safe, check if we can animate on load
        $(window).on("load", function() {

            if(!canWe){
                return false;
            }

            em.chart.animate();
        });
    };

    em.chart.animate = function(){
        em.chart.elements.each(function(){

            var win = $(window),
                el = $(this),
                type = el.data('type'),
                scrollTop = win.scrollTop(),
                windowHeight = win.height(),
                elTop = el.offset().top,
                isInitialized = el.hasClass('initialized'),
                isVisible = elTop < (scrollTop+windowHeight);

            if (isVisible && !isInitialized) {
                //em.chart.setBorderWidth(el);
                el.chart = em.chart.build(el, type, em.chart.options[type]);
                el.addClass('initialized');
            }
        });
    };

    em.chart.resize = function() {
        em.chart.elements.each(function(){
            //em.chart.setBorderWidth($(this));
        });
    };

})();

/*(function() {

	//create empty object in the global em var, dont forget to add the init call in the main.js!
	em.chart = {};

	//call any functions to be trigger on dom ready
	em.chart.init = function(){
		em.chart.options();
		em.chart.capture();
		em.chart.checkRequiredWidth();
		em.chart.setup();
	};

	em.chart.options = function() {
		//list of options at http://www.chartjs.org/docs/
		//Chart.defaults.global.responsive = true;
		//Chart.defaults.global.showScale = false; //uncomment to hide all grids and labels
		Chart.defaults.global.tooltipFontFamily = "'Roboto', 'Helvetica', sans-serif";
		Chart.defaults.global.tooltipFontSize = 12;
		Chart.defaults.global.tooltipFillColor = "#fff";
		Chart.defaults.global.tooltipFontColor = "#000";
		Chart.defaults.global.tooltipYPadding = 7;
		Chart.defaults.global.tooltipXPadding = 10;
		Chart.defaults.global.tooltipCornerRadius = 0;
		Chart.defaults.global.tooltipCaretSize = 5;
		Chart.defaults.global.tooltipTemplate = "<%if (label){%> <%=label%><%}%>";

		em.chart.options.line = {
			datasetFill: false
		};
		em.chart.options.bar = {
			barShowStroke : false
		};
		em.chart.options.pie = {};
		em.chart.options.doughnut = {
			segmentShowStroke: false,
			animationEasing : "easeOutExpo"
		};
		em.chart.options.radar = {
			datasetFill: false
		};
		em.chart.options.polar = {};

		//list of options at https://inorganik.github.io/countUp.js/
		em.chart.options.counter = {
			useEasing : true,
			useGrouping : true,
			separator : ' ',
			decimal : ' ',
			prefix : '',
			suffix : ''
		};
	};

	em.chart.capture = function(){
		em.chart.elements = $('.c-chart__item');
	};

	em.chart.checkRequiredWidth = function(){
		if(window.innerWidth>1024){
			em.chart.winWidthOk = true;
			em.chart.animate();
		}
		else {
			em.chart.winWidthOk = false;
		}

	};

	em.chart.canWe = function(){
		if(em.chart.elements.length && em.chart.winWidthOk === true) {
			return true;
		}

		return false;
	};

	em.chart.setup  = function(){

		var canWe = em.chart.canWe();

		// if on mobile, build charts immediately
		if(!canWe) {
			em.chart.elements.each(function(){
				var el = $(this),
					type = el.data('type');

				el.chart = em.chart.build(el, type, em.chart.options[type]);
				el.addClass('initialized');
			});
		}

		// otherwise let's wait for them to scroll into view
		$(window).on("scroll", function() {

			if(!canWe){
				return false;
			}

			em.chart.animate();

		}).scroll();

		// just to be safe, check if we can animate on load
		$(window).on("load", function() {

			if(!canWe){
				return false;
			}

			em.chart.animate();
		});
	};

	em.chart.animate = function(){
		em.chart.elements.each(function(){

			var win = $(window),
				el = $(this),
				type = el.data('type'),
				scrollTop = win.scrollTop(),
				windowHeight = win.height(),
				elTop = el.offset().top,
				isInitialized = el.hasClass('initialized'),
				isVisible = elTop < (scrollTop+windowHeight);

			if (isVisible && !isInitialized) {
				em.chart.setBorderWidth(el);
				el.chart = em.chart.build(el, type, em.chart.options[type]);
				el.addClass('initialized');
			}
		});
	};

	em.chart.build = function(el, type, options) {
		type = typeof type !== 'undefined' ? type : 'line';
		options = typeof options !== 'undefined' ? options : {};

		var data = el.data('chart');
		var canvas = el.find('.c-chart__item__canvas');
		var ctx;

		if (canvas.length > 0) {
			ctx = canvas[0].getContext("2d");
		}

		switch (type) {
			case 'line':
				return new Chart(ctx).Line(data, options);
			case 'bar':
				return new Chart(ctx).Bar(data, options);
			case 'pie':
				return new Chart(ctx).Pie(data, options);
			case 'doughnut':
				return new Chart(ctx).Doughnut(data, options);
			case 'radar':
				return new Chart(ctx).Radar(data, options);
			case 'polar':
				return new Chart(ctx).PolarArea(data, options);
			case 'counter':
				var counter = new CountUp(el.find('.c-chart__item__content')[0], data.startValue, data.endValue, 0, data.speed, options);
				counter.start();
				return counter;
			default:
				return false;
		}
	};

	em.chart.setBorderWidth = function(el) {
		var canvas = el.find('.c-chart__item__canvas');
		var border = el.find('.c-chart__item__border');

		border.css({
			'width': canvas.width()
		});
	};

	em.chart.resize = function() {
		em.chart.elements.each(function(){
			em.chart.setBorderWidth($(this));
		});
	};

})();*/